<template>
  <div class="panel">
    <el-form label-position="top" size="mini" v-model="scheduleForm">
      <el-row>
        <el-col :span="12" :offset="6" label="Нээх цаг">
          <el-form-item label="Даваа">
            <el-row :gutter="20">
              <el-time-picker
                style="width:30%"
                size="mini"
                v-model="page_time_table.mon[0]"
                value-format="HH:mm"
                :disabled="mArrayIncludes(role, ['finance', 'other'])"
              >
              </el-time-picker>
              <el-time-picker
                style="width:30%"
                size="mini"
                v-model="page_time_table.mon[1]"
                value-format="HH:mm"
                :disabled="mArrayIncludes(role, ['finance', 'other'])"
              >
              </el-time-picker>
            </el-row>
          </el-form-item>
          <el-form-item label="Мягмар">
            <el-row :gutter="20">
              <el-time-picker
                style="width:30%"
                size="mini"
                v-model="page_time_table.tue[0]"
                value-format="HH:mm"
                :disabled="mArrayIncludes(role, ['finance', 'other'])"
              >
              </el-time-picker>
              <el-time-picker
                style="width:30%"
                size="mini"
                v-model="page_time_table.tue[1]"
                value-format="HH:mm"
                :disabled="mArrayIncludes(role, ['finance', 'other'])"
              >
              </el-time-picker>
            </el-row>
          </el-form-item>
          <el-form-item label="Лхагва">
            <el-row :gutter="20">
              <el-time-picker
                style="width:30%"
                size="mini"
                v-model="page_time_table.wed[0]"
                value-format="HH:mm"
                :disabled="mArrayIncludes(role, ['finance', 'other'])"
              >
              </el-time-picker>
              <el-time-picker
                style="width:30%"
                size="mini"
                v-model="page_time_table.wed[1]"
                value-format="HH:mm"
                :disabled="mArrayIncludes(role, ['finance', 'other'])"
              >
              </el-time-picker>
            </el-row>
          </el-form-item>
          <el-form-item label="Пүрэв">
            <el-row :gutter="20">
              <el-time-picker
                style="width:30%"
                size="mini"
                v-model="page_time_table.thu[0]"
                value-format="HH:mm"
                :disabled="mArrayIncludes(role, ['finance', 'other'])"
              >
              </el-time-picker>
              <el-time-picker
                style="width:30%"
                size="mini"
                v-model="page_time_table.thu[1]"
                value-format="HH:mm"
                :disabled="mArrayIncludes(role, ['finance', 'other'])"
              >
              </el-time-picker>
            </el-row>
          </el-form-item>
          <el-form-item label="Баасан">
            <el-row :gutter="20">
              <el-time-picker
                style="width:30%"
                size="mini"
                v-model="page_time_table.fri[0]"
                value-format="HH:mm"
                :disabled="mArrayIncludes(role, ['finance', 'other'])"
              >
              </el-time-picker>
              <el-time-picker
                style="width:30%"
                size="mini"
                v-model="page_time_table.fri[1]"
                value-format="HH:mm"
                :disabled="mArrayIncludes(role, ['finance', 'other'])"
              >
              </el-time-picker>
            </el-row>
          </el-form-item>
          <el-form-item label="Бямба">
            <el-row :gutter="20">
              <el-time-picker
                style="width:30%"
                size="mini"
                v-model="page_time_table.sat[0]"
                value-format="HH:mm"
                :disabled="mArrayIncludes(role, ['finance', 'other'])"
              >
              </el-time-picker>
              <el-time-picker
                style="width:30%"
                size="mini"
                v-model="page_time_table.sat[1]"
                value-format="HH:mm"
                :disabled="mArrayIncludes(role, ['finance', 'other'])"
              >
              </el-time-picker>
            </el-row>
          </el-form-item>
          <el-form-item label="Ням">
            <el-row :gutter="20">
              <el-time-picker
                style="width:30%"
                size="mini"
                v-model="page_time_table.sun[0]"
                value-format="HH:mm"
                :disabled="mArrayIncludes(role, ['finance', 'other'])"
              >
              </el-time-picker>
              <el-time-picker
                style="width:30%"
                size="mini"
                v-model="page_time_table.sun[1]"
                value-format="HH:mm"
                :disabled="mArrayIncludes(role, ['finance', 'other'])"
              >
              </el-time-picker>
            </el-row>
          </el-form-item>
          <el-row :gutter="24">
            <el-col :span="8">
              <el-button
                type="success"
                size="medium"
                class="full-width"
                :disabled="
                  !mArrayIncludes(role, ['boss', 'admin', 'menu_editor', 'ops'])
                "
                @click="goPartnerAdditional"
                >Хадгалах</el-button
              >
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import service from "@/helpers/services.js";
import { getUserName, getGroups } from "../utils/auth";
import { arrayIncludes } from "../helpers/helper";
export default {
  created() {
    this.openFullScreenLoader(true);
    const payload = {
      includeFields: [
        "time_mon",
        "time_tue",
        "time_wed",
        "time_thur",
        "time_fri",
        "time_sat",
        "time_sun"
      ]
    };
    service.getOutletById(this.$route.query._id, payload).then(timeTable => {
      this.scheduleForm.id = this.$route.query._id;
      this.scheduleForm.time_table = timeTable.data.data.timetable;
      this.page_time_table.mon.push(
        timeTable.data.data.timetable.mon.startTime
      );
      this.page_time_table.mon.push(timeTable.data.data.timetable.mon.endTime);
      this.page_time_table.tue.push(
        timeTable.data.data.timetable.tue.startTime
      );
      this.page_time_table.tue.push(timeTable.data.data.timetable.tue.endTime);
      this.page_time_table.wed.push(
        timeTable.data.data.timetable.wed.startTime
      );
      this.page_time_table.wed.push(timeTable.data.data.timetable.wed.endTime);
      this.page_time_table.thu.push(
        timeTable.data.data.timetable.thu.startTime
      );
      this.page_time_table.thu.push(timeTable.data.data.timetable.thu.endTime);
      this.page_time_table.fri.push(
        timeTable.data.data.timetable.fri.startTime
      );
      this.page_time_table.fri.push(timeTable.data.data.timetable.fri.endTime);
      this.page_time_table.sat.push(
        timeTable.data.data.timetable.sat.startTime
      );
      this.page_time_table.sat.push(timeTable.data.data.timetable.sat.endTime);
      this.page_time_table.sun.push(
        timeTable.data.data.timetable.sun.startTime
      );
      this.page_time_table.sun.push(timeTable.data.data.timetable.sun.endTime);
    });
    getUserName().then(name => {
      this.scheduleForm.user = name.attributes.email.split("@")[0];
    });
    this.openFullScreenLoader(false);
    getGroups().then(data => {
      this.role = data;
      if (this.role.includes("address_editor")) {
        this.$router.push({
          path: "/list-address"
        });
      }
    });
  },
  data() {
    return {
      loading: null,
      value1: "",
      role: [],
      page_time_table: {
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: []
      },
      scheduleForm: {
        user: "",
        id: "",
        time_table: {
          mon: {
            startTime: "09:00",
            endTime: "23:00"
          },
          tue: {
            startTime: "09:00",
            endTime: "23:00"
          },
          wed: {
            startTime: "09:00",
            endTime: "23:00"
          },
          thu: {
            startTime: "09:00",
            endTime: "23:00"
          },
          fri: {
            startTime: "09:00",
            endTime: "23:00"
          },
          sat: {
            startTime: "09:00",
            endTime: "23:00"
          },
          sun: {
            startTime: "09:00",
            endTime: "23:00"
          }
        }
      }
    };
  },
  methods: {
    openFullScreenLoader(status) {
      if (status) {
        this.loading = this.$loading({
          lock: true,
          text: "Харилцагчийн цагийн хуваарь татаж авч байна...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
      } else {
        this.loading.close();
      }
    },
    nextpage() {
      this.$router.push({
        path: "partner-additional-information",
        query: {
          _id: this.$route.query._id
        }
      });
    },
    goPartnerAdditional() {
      if (
        this.page_time_table.mon != null &&
        this.page_time_table.tue != null &&
        this.page_time_table.wed != null &&
        this.page_time_table.thu != null &&
        this.page_time_table.fri != null &&
        this.page_time_table.sat != null &&
        this.page_time_table.sun != null
      ) {
        this.scheduleForm.time_table.mon.startTime = this.page_time_table.mon[0];
        this.scheduleForm.time_table.mon.endTime = this.page_time_table.mon[1];
        this.scheduleForm.time_table.tue.startTime = this.page_time_table.tue[0];
        this.scheduleForm.time_table.tue.endTime = this.page_time_table.tue[1];
        this.scheduleForm.time_table.wed.startTime = this.page_time_table.wed[0];
        this.scheduleForm.time_table.wed.endTime = this.page_time_table.wed[1];
        this.scheduleForm.time_table.thu.startTime = this.page_time_table.thu[0];
        this.scheduleForm.time_table.thu.endTime = this.page_time_table.thu[1];
        this.scheduleForm.time_table.fri.startTime = this.page_time_table.fri[0];
        this.scheduleForm.time_table.fri.endTime = this.page_time_table.fri[1];
        this.scheduleForm.time_table.sat.startTime = this.page_time_table.sat[0];
        this.scheduleForm.time_table.sat.endTime = this.page_time_table.sat[1];
        this.scheduleForm.time_table.sun.startTime = this.page_time_table.sun[0];
        this.scheduleForm.time_table.sun.endTime = this.page_time_table.sun[1];
      } else {
        this.$message({
          showClose: true,
          message: "Зарим цагийн талбарууд хоосон байна",
          type: "error"
        });
      }
      service
        .editPartnerSchedule(this.$route.query._id, this.scheduleForm)
        .then(response => {
          this.$router.push({
            path: "partner-additional-information",
            query: { _id: this.$route.query._id }
          });
          this.$notify({
            title: "Success",
            message: response.data === "" ? "амжилттай" : "амжилттай",
            type: "success"
          });
        });
    },
    mArrayIncludes(base_array, searchArray) {
      return arrayIncludes(base_array, searchArray);
    }
  }
};
</script>
